import React, { useState } from "react";
import emailjs from '@emailjs/browser';
import "../css/Contact.css";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import contact from "../Assets/contact.jpg";
import bannerBtm from "../Assets/banner-btm.png";
import contactLeft from "../Assets/contact-left.png";

function Contact() {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    countryCode: '+', // Default + value for country code
    message: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    
    // Allow only numeric values for phoneNumber and countryCode
    if (name === 'phoneNumber' || name === 'countryCode') {
      setFormData({
        ...formData,
        [name]: value.replace(/[^0-9+]/g, ''),
      });
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const { firstName, lastName, email, phoneNumber, countryCode, message } = formData;

    if (!firstName || !lastName || !email || !phoneNumber || !countryCode || !message) {
      toast.error('All fields are mandatory');
      return;
    }

    // Ensure phoneNumber and countryCode are valid
    if (!/^\+?\d+$/.test(countryCode)) {
      toast.error('Country code should be valid');
      return;
    }

    if (!/^\d+$/.test(phoneNumber)) {
      toast.error('Phone number should only contain numbers');
      return;
    }

    // Send email using emailjs
    emailjs.send(SERVICE_ID, TEMPLATE_ID, formData, USER_ID)
      .then((result) => {
        toast.success('Thank You. We will contact you shortly!');
        setFormData({
          firstName: '',
          lastName: '',
          email: '',
          phoneNumber: '',
          countryCode: '+',
          message: '',
        });
      })
      .catch((error) => {
        toast.error('Oops! Something went wrong. Please try again.');
        console.error('EmailJS error:', error);
      });
  };

  const SERVICE_ID = 'service_dorcyxk';
  const TEMPLATE_ID = 'template_bp29ici';
  const USER_ID = 'QAVyY1uLrmii4TWyl';

  return (
    <div className="contact">
      <div className="contact-top">
        <img className="img-one" src={contact} alt="Contact"></img>
      </div>
      <img className="banner-btm" src={bannerBtm} alt="Banner Bottom"></img>
      <div className="contact-section">
        <div className="section-left">
          <img src={contactLeft} alt="Contact Left"></img>
        </div>
        <div className="section-right">
          <div
            style={{
              display: "flex",
              gap: "10px",
              alignItems: "center",
              marginBottom: "2rem",
            }}
          >
            <h2 className="title-one">We Love To</h2>
            <h3 className="title-two">Hear From You</h3>
          </div>
          <form className="contact-form" onSubmit={handleSubmit}>
            <input
              type="text"
              name="firstName"
              placeholder="First Name"
              value={formData.firstName}
              onChange={handleChange}
              required
            />
            <input
              type="text"
              name="lastName"
              placeholder="Last Name"
              value={formData.lastName}
              onChange={handleChange}
              required
            />
            <input
              type="email"
              name="email"
              placeholder="Email"
              value={formData.email}
              onChange={handleChange}
              required
            />
            <div className="phone-number-group" style={{ display: "flex", gap: "10px" }}>
              <input
                type="text" 
                name="countryCode"
                placeholder="Code"
                value={formData.countryCode}
                onChange={handleChange}
                required
                style={{ width: "80px" }}
              />
              <input
                type="text"
                name="phoneNumber"
                placeholder="Phone Number"
                value={formData.phoneNumber}
                onChange={handleChange}
                required
                style={{ flex: "1" }}
              />
            </div>
            <input
              type="text"
              name="message"
              placeholder="Message"
              value={formData.message}
              onChange={handleChange}
              required
            />
            <div style={{ display: "flex", justifyContent: "center" }}>
              <button type="submit" className="submit-btn">
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}

export default Contact;
